@import '../../assets/styles/core';
@import '../../assets/styles/variables';

.timelineContainer {
  //overflow-x: auto;
  //
  position: relative;
  display: flex;
  //padding: 0 1rem 1rem 0;
  //margin: 0 1.5rem;
  //background-size: 1px 37px;
  //background-repeat: repeat-x;
  //background-image:
  //  linear-gradient(
  //    0deg,
  //    transparent 25px,
  //    #bababa 28px,
  //    transparent 26px,
  //    transparent
  //  );
  //background-position: 0 0;

  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #eee;
  }

  > * {
    flex: 0 0 auto;
  }

  .dotContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 70px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      top: 11px;
      left: 50%;
      background: map-get($theme-colors, 'gray-light');
    }

    &:last-child::before {
      content: none;
    }

    .popover {
      width: 300px;
      max-width: 100vw;
    }

    .dot {
      background-color: #fff;
      border: 2px solid #bababa;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.created {
      .dot {
        border-color: #1b48a0;
        background-color: #e0ebfd;
        height: 17px;
        width: 17px;
      }

      &::before {
        background: #1b48a0;
      }
    }

    &.approved {
      .dot {
        border-color: #4db07f;
        background-color: #4db07f;
      }

      &::before {
        background: #4db07f;
      }
    }

    &.rejected {
      .dot {
        border-color: #e74646;
        background-color: #e74646;
      }

      &::before {
        background: #e74646;
      }
    }

    &.current {
      .dot {
        border-color: #fabe24;
        background-color: #fcf0ba;
        border-radius: 3px;
      }

      &::before {
        background: #fabe24;
      }
    }

    &.done {
      .dot {
        border-color: var(--success);
        background-color: var(--success-light);
      }

      &::before {
        background: var(--success);
      }
    }
  }
}

.squared {
  border-radius: 0;
}
