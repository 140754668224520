.datepicker {
  :global {
    // Removing styles applied when using withPortal prop
    .react-datepicker__portal {
      width: initial;
      height: initial;
      background-color: initial;
      left: initial;
      top: initial;
      justify-content: initial;
      align-items: initial;
      display: initial;

      .react-datepicker__current-month {
        font-size: 0.944rem;
      }

      .react-datepicker__navigation {
        border: 0.45rem solid transparent;
      }

      .react-datepicker__navigation--previous {
        border-right-color: #ccc;
      }

      .react-datepicker__navigation--next {
        border-left-color: #ccc;
      }

      .react-datepicker__day {
        width: 3rem;
        line-height: 1.7rem;
      }
    }
  }
}
