.input {
  display: none;
}

.labelUpload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.thumbnailImage {
  img {
    border-radius: 0.25rem;
    height: 70px;
    object-fit: cover;
    width: 100px;
  }
}

.imageLink {
  display: inline-block;
  margin-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.fileIcon {
  display: flex;
  justify-content: center;
  width: 100px;

  i {
    font-size: 3.5rem;
  }
}
