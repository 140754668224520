@import 'assets/styles/variables.scss';

.form-group {
  textarea {
    height: auto !important;
  }

  .form-control {
    padding: $spacer * 0.6;
    height: $spacer * 3;
    border-radius: 5px;

    input {
      background-color: inherit;
      border: none;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    i {
      color: var(--primary);
    }
  }
}

.fieldset-body {
  margin-top: 1rem;
}

.section-toggle {
  width: 100%;
  color: var(--primary);
  font-weight: 700;
  display: flex;
  justify-items: center;
  padding-right: 0.5rem;

  &.is-visible {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  div:first-child {
    flex-grow: 1;
  }

  div.toggle {
    color: var(--primary);
    text-align: center;
    font-size: 120%;
  }

  &:hover {
    text-decoration: underline;
    // background-color: var(--primary);
  }
}

.date-picker-container {
  position: relative;
}

.date-picker-icon {
  position: absolute;
  top: 14px;
  right: 10px;
  color: var(--primary);
}
