.modal {
  .header {
    border: none;
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: white;
  }

  @media (max-width: 768px) {
    :global {
      .modal-dialog {
        margin: 0.5rem;
        max-width: initial;
      }
    }
  }
}
