/* stylelint-disable */

@import 'assets/styles/variables';
@import 'assets/styles/override-bootstrap.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import 'assets/styles/ngprogress';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.css';
@import 'assets/styles/ag-grid';
@import '~react-datepicker/dist/react-datepicker.css';
@import 'assets/styles/react-datepicker';

h1 {
  color: var(--primary);
  font-weight: 800;
}

h4,
h5,
h6 {
  color: var(--primary);
  font-weight: 900;
}

body {
  background-color: var(--light);
  font-family: $font-family-base !important;
  min-height: 100vh;
}

.text-primary {
  color: var(--primary) !important;
}

.text-primary-light {
  color: var(--primary-light) !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.table {
  td {
    vertical-align: middle;
  }
}

.letter-spacig-lg {
  letter-spacing: 1.5px;
}

/**
 * BUTTONS
 */
.btn {
  @media (max-width: 768px) {
    font-size: $mobile-font-size;
  }
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover,
  &:focus {
    background-color: var(--primary-darker);
    border-color: var(--primary-darker);
  }
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
}

@each $color, $value in $theme-colors {
  .badge-#{$color},
  .bg-#{$color} {
    background-color: var(--#{$color}) !important;
  }

  .btn-outline-#{$color},
  .btn-outline-#{$color}.dropdown-toggle {
    //background-color: var(--#{$color}) !important;
    color: var(--#{$color});
    border-color: var(--#{$color});

    &:not(:disabled):not(.disabled):active,
    &:active,
    &:hover {
      color: white;
      background-color: var(--#{$color});
      border-color: var(--#{$color});
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:focus {
      box-shadow: 0 0 0.7rem -0.2rem var(--#{$color});
    }
  }

  .btn-#{$color}.disabled,
  .btn-#{$color}:disabled {
    background-color: var(--#{$color}) !important;
    border-color: var(--#{$color});
  }
  .border-left-#{$color} {
    border-left-color: $value !important;
  }
  .border-right-#{$color} {
    border-right-color: $value !important;
  }
  .border-top-#{$color} {
    border-top-color: $value !important;
  }
  .border-bottom-#{$color} {
    border-bottom-color: $value !important;
  }
}

// The validation is failing because of .show .btn-primary-light.dropdown-toggle
// I don't know how to solve the lint apart from duplicating the code.
.btn-primary-light,
.btn-primary-light.dropdown-toggle,
.show .btn-primary-light.dropdown-toggle {
  color: $black;
  background-color: var(--primary-lightest);
  border-color: var(--primary-lightest);

  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    color: $black;
    background-color: var(--primary-light);
    border-color: var(--primary-light);
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0.7rem -0.2rem var(--primary-light);
  }
}

/**
 * MODAL
 */
@for $i from 1 through 50 {
  $zIndexBackdrop: #{1040 + (5 * $i)};
  $zIndexContent: #{1040 + (5 * $i) + 2};

  .modal-backdrop.show {
    &.modal-level-#{$i} {
      z-index: $zIndexBackdrop;
    }
  }

  div[role='dialog'][aria-modal='true'] {
    &.modal-level-#{$i} {
      z-index: $zIndexContent;
    }
  }
}

/**
 * OTHERS
 */
.list-group-item {
  &.active {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.shadow-sm {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25) !important;
}

.fade {
  will-change: opacity;
}

.modal.fade .modal-dialog {
  will-change: transform;
}

.modal-backdrop.show {
  will-change: opacity;
}

.object-fit-contain {
  object-fit: contain;
}

.dropdown-menu {
  z-index: 1002;
}

// TODO: mode modalContent classes to a specific component
// this was coppied from Entity.js
.modal-content {
  .modal-title {
    font-weight: $font-weight-bold;
    font-family: $font-family-base;

    h4 {
      margin: 0;
    }
  }

  .nav-pills {
    height: $spacer * 3;
  }

  .dropdown-menu {
    border: none;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0;

    .dropdown-item {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(85, 52, 230, 0.2);
      }
    }
  }

  .modal-header {
    padding: 1rem 2rem !important;

    .toggle-icon {
      background-color: var(--primary);
      border-radius: $border-radius-round;
      height: $spacer * 2;
      line-height: $spacer * 2;
      width: $spacer * 2;
      text-align: center;
      cursor: pointer;

      i {
        font-size: $spacer * 1;
        color: var(--white);
        vertical-align: middle;
      }
    }
  }

  // Modal
  .modal-body {
    padding: 0;
  }
}

// TODO: mode navTabs classes to a specific component
// this was coppied from Entity.js
.nav-tabs {
  display: flex;

  @media (max-width: 768px) {
    font-size: $mobile-font-size;
  }

  .nav-link {
    height: $spacer * 2;
    font-weight: $font-weight-bold;
    padding-bottom: $spacer * 2;
    border: none;
    border-bottom: var(--primary-light) 2px solid;
    text-align: center;
    color: var(--primary-light);
    background: none;

    &.active {
      color: var(--primary);
      border-bottom-color: var(--primary);
      background: none;
    }
  }
}

.no-shadow {
  box-shadow: none !important;
}

.line-height-initial {
  line-height: initial !important;
}

.z-100 {
  z-index: 100;
}

.flex-basis-auto {
  flex-basis: auto !important;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}
.opacity-50 {
  opacity: 0.5;
}

.rounded-md {
  border-radius: 14px;
}

.pointer-event-none {
  pointer-events: none;
  touch-action: none;
}
