// Extending react-datepicker
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 1020;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  width: 100px;
}
