.Dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: dashed 2px var(--primary);
  color: var(--primary);
  outline: none;
  transition: border 0.24s ease-in-out;
}
