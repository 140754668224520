.fields {
  max-height: 230px;
  overflow: auto;
  padding: 1rem;
  white-space: initial;
  word-break: break-all;

  &.horizontalScroll {
    white-space: nowrap;
  }

  :global {
    input[type='checkbox'],
    label {
      cursor: pointer;
    }
  }
}

.apply {
  border-top: 1px solid var(--light-grey);
  padding: 1rem;
}
