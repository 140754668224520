@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'assets/styles/core.scss';

$font-style: normal;
$font-family-base: proxima-nova, sans-serif;
$font-family-secondary: 'Open Sans', sans-serif;
$grid-gutter-width: 30px;
$card-border-radius: 3px;

$colors: (
  grey-light: #f1f1f1,
  grid-viewport-background: #f4f4f6,
);
$border-width: 1px;
$red: #dc3545;
$green: #28a745;

$theme-colors: map-merge(
  (
    primary: #5534e6,
    primary-light: lighten(#5534e6, 20),
    primary-extra-light: lighten(#5534e6, 40),
    danger-light: lighten($red, 42),
    success-light: lighten($green, 55),
    light-grey: #e9e9e9,
    gray-light: #c1cdcf,
    warning-light: lighten($yellow, 40),
    success: $green,
    danger: $red,
  ),
  $theme-colors
);
//$theme-colors: ;

// Navbar
$navbar-min-height: ($spacer * 6) + 0.063rem;
$border-radius-round: 50%;

// Typography
$font-weight-bolder: 900;
$mobile-font-size: 0.9rem;
