/* stylelint-disable */
.root {
  font-size: 12px;
  margin-bottom: 1rem;

  .fieldset {
    background-color: var(--white);
    border: 1px solid #ced4da;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;

    .fieldKey > div {
      background-color: var(--white);
      padding: 0.5rem;
      overflow: hidden;
      border: 1px solid #ced4da;
      border-radius: 5px;
    }

    .fieldKey {
      > div::before {
        background-color: var(--white);
        border-radius: 3px;
        border: 1px solid var(--light-grey);
        color: var(--gray);
        display: inline-block;
        padding: 3px;
        margin-right: 0.5rem;
        width: 28px;
        height: 25px;
        text-align: center;
      }

      &.Grid > div::before {
        content: 'G';
      }

      &.TextField > div::before {
        content: 'Ab';
      }

      &.NumericField > div::before {
        content: '123';
      }

      &.NumericField.precision > div::before {
        content: '.10';
      }

      &.Checkbox > div::before {
        content: '✓';
      }

      &.DateField > div::before {
        content: 'D';
      }

      &.FileField > div::before {
        content: 'F';
      }

      &.DateField.time > div::before {
        content: 'DT';
      }

      &.HTMLField > div::before,
      &.MonacoEditorField > div::before {
        content: '< />';
      }

      &.MonacoEditorField.json > div::before {
        content: '{ }';
      }

      &.OptionSet > div::before {
        content: 'Os';
      }

      &.SingleDropDown,
      &.MultiDropDown {
        > div::before {
          content: 'Dd';
        }
      }

      &.MultiDropDown,
      &.SingleDropDown {
        > div::after {
          border-style: solid;
          border-width: 0.15em 0.15em 0 0;
          content: '';
          height: 0.45em;
          width: 0.45em;
          position: relative;
          transform: rotate(-45deg);
          transform: rotate(135deg);
          margin-top: 8px;
          float: right;
        }
      }

      &.multiline > div {
        height: 80px;
      }

      &.readonly > div {
        background: #eee;
      }
    }
  }
}

.gridHeader {
  border-bottom: 1px solid var(--grey-light);
  margin-bottom: 0.2rem;
  padding-bottom: 0.2rem;
}
