@import 'assets/styles/core.scss';

.EntityLogList {
  .body {
    padding-top: 8px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 40px;

    .log {
      padding-bottom: $spacer;
    }


    .log:not(:first-child) {
      padding-top: $spacer;
    }

    .log-action {
      display: flex;

      .circle {
        &:first-child {
          z-index: 1;
        }
      }
    }

  }

  .footer {
    position:absolute;
    bottom: 0;
    overflow: none;
  }


}

.EntityLogTable {
  th, td {
    max-width: 300px;
  }

  .highlight {
    background-color: #FBEEB8;
  }
}
