/* stylelint-disable */

.popover {
  position: relative;

  /* Width */
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--secondary);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--light);
    margin-right: 10px;
  }
}

.toggleButton {
  background-color: transparent;
  border-radius: 5px;
  border: 0;
  color: var(--primary);
  margin: 0;
  padding: 0;

  &:focus {
    outline: none !important;
  }

  &:hover {
    color: var(--primary);
  }

  &.isActive {
    span {
      text-decoration: underline;
    }
  }

  &.showDrop:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

.content {
  background-clip: padding-box;
  background-color: var(--white);
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: none;
  float: left;
  inset: 0px auto auto 0px;
  list-style: none;
  margin: 0px;
  min-width: 10rem;
  padding: 0;
  position: absolute;
  text-align: left;
  transform: translate(0px, 30px);
  z-index: 1001;

  &.isActive {
    display: block;
  }
}
