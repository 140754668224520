/* stylelint-disable */

.root {
  :global {
    .react-calendar-timeline {
      background-color: var(--white);

      .rct-header-root {
        background-color: var(--white);

        > div {
          padding-left: 3px;
          align-self: center;
        }

        .rct-calendar-header {
          border-bottom: none;

          > div:first-child {
            height: 50px !important;
          }

          > div:last-child {
            .rct-dateHeader {
              background-color: var(--white);
              border-bottom: none;
              border-left: none;
              border-top: 1px solid var(--light-grey);
              border-right: 1px solid var(--light-grey);
            }
          }
        }
      }

      .rct-dateHeader-primary {
        color: var(--black);
        border-bottom: none;
      }

      .rct-vertical-lines {
        display: none !important;
      }

      .rct-sidebar .rct-sidebar-row {
        font-size: 80%;
      }

      .rct-calendar-header,
      .rct-sidebar,
      .rct-sidebar .rct-sidebar-row,
      .rct-horizontal-lines .rct-hl-even,
      .rct-hl-odd,
      .rct-dateHeader-primary,
      .rct-header-root {
        border-color: var(--light-grey);
        background: transparent;
      }

      .rct-items .rct-item {
        background-color: var(--light) !important;
        // border-top-width: 1px !important;
        // border-bottom-width: 1px !important;
        // border-left-width: 1px !important;
        // border-right-width: 1px !important;
        color: #000 !important;

        &.worflow-approved {
          background-color: var(--success-light) !important;
        }

        &.worflow-rejected {
          background-color: var(--danger-light) !important;
        }

        & > div {
          padding-left: 8px !important;
        }

        > .rct-item-content span {
          font-weight: 600;
        }
      }

      .rct-items .rct-item:hover {
        background-color: var(--light-grey) !important;
        // border-top: 1px solid var(--light-grey) !important;
        // border-right: 1px solid var(--light-grey) !important;
        // border-bottom: 1px solid var(--light-grey) !important;

        &.worflow-approved,
        &.worflow-approved > .rct-item-content {
          background-color: var(--success) !important;
          color: #fff !important;
        }

        &.worflow-rejected,
        &.worflow-rejected > .rct-item-content {
          background-color: var(--danger) !important;
          color: #fff !important;
        }
      }
    }
  }
}
