.EntityHistoryListItem {
  .history-action {
    .circle {
      &:last-child {
        transform: translateX(-20%);
      }
    }
  }

  .history-detail-date {
    font-size: 0.9rem;
  }

  .history-detail-table {
    width: auto;

    td,
    th {
      font-size: 0.9rem;
      padding-bottom: 0;
      padding-top: 0.2rem;
    }

    td:first-child,
    th:first-child {
      padding-left: 0;
    }
  }
}
