@import 'assets/styles/variables.scss';

.entity-actionbar {
  min-height: 63px;
  height: auto;
  background-color: white !important;
  position: sticky;
  top: 60px;
  z-index: 1020;

  .navbar-nav {
    align-items: center;

    .nav-item {
      a {
        color: var(--primary);
      }

      button:hover {
        border-radius: $border-radius;
      }

      &:not(:last-child):not(:first-child) {
        border-left: 1px solid rgba(255, 255, 255, 0.3);
      }
    }

    .dropdown-menu {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .dropdown-item {
        font-size: $spacer - 0.125;
        border-radius: 0;
        margin: auto;
        height: $spacer * 2.75;
        line-height: $spacer * 1.75;
        padding-left: 22px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
