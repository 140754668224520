.Login {
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 10rem 0;
  background-size: cover;
}

.company-logo {
  max-width: 100%;
  height: 60px;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: scale(1.1) translateY(-30px);
  }
}

.login-card {
  opacity: 1;
  transform: translateY(0);
  animation: slideDown 0.5s ease forwards;
  will-change: transform, opacity;
}
