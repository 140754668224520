@import 'assets/styles/variables.scss';

.row-pointer {
  cursor: pointer;

  .ag-cell-focus {
    border: none !important;
  }
}

.EntityTable {
  .ag-header-cell-resize {
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      display: block;
      left: calc(50% - 1px);
      width: 2px;
      height: 30%;
      top: 35%;
      background-color: rgba(186, 191, 199, 0.5);
      background-color: var(
        --ag-header-column-resize-handle-color,
        rgba(186, 191, 199, 0.5)
      );
    }
  }

  .ag-header-viewport {
    background-color: var(--grid-viewport-background);
  }

  .ag-header-cell-text {
    color: var(--primary);
    font-family: $font-family-base;
    text-transform: uppercase;
  }

  .ag-header-cell {
    background: #f4f4f6;
  }

  .ag-cell {
    vertical-align: middle;
    display: flex;
    flex-direction: column;

    .ag-react-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .ag-row-hover {
    background: rgba(85, 52, 230, 0.1) !important;
    z-index: 1000;
  }
}
