.automation-preview {
  display: block;
  width: 100%;
  height: 80vh;
  min-height: 600px;
}

.hookModal {
  max-width: 90vw;
}
