@import 'assets/styles/core.scss';

.root {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  /*
  * Sidebar
  */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

    .sidebar-sticky {
      position: relative;
      top: 0;
      height: calc(100vh - 48px);
      padding-top: 0.5rem;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */

      @supports ((position: -webkit-sticky) or (position: sticky)) {
        position: -webkit-sticky;
        position: sticky;
      }
    }

    .sidebar-heading {
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    @media (max-width: 767.98px) {
      top: $spacer * 5;
    }
  }
}
