.root {
  display: inline-flex;
  margin-top: 0.5rem;

  > a:hover {
    text-decoration: none;
  }

  &.isInvalid {
    .appliedFilter {
      background-color: var(--danger);
      border-color: var(--danger);
      color: var(--white);
    }
  }
}

.appliedFilterDropdown {
  :global {
    .dropdown-toggle {
      padding: 0;

      &::after {
        display: none;
      }
    }
  }
}

.appliedFilter {
  background-color: #b0c3d2;
  border-color: #b0c3d2;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #000;
  font-weight: 600;

  .label {
    cursor: inherit;
    font-weight: normal;
    margin-bottom: 0;
    text-decoration: none;
  }
}

.removeAppliedFilter {
  background-color: #cad4dc;
  border-color: #cad4dc;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #000;
  font-weight: 600;
  margin-right: 0.5rem;
}

.loader {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
