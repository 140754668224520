@import '../../assets/styles/variables.scss';

.root {
  overflow-x: hidden;
  flex: 1;
  position: relative;
  margin-top: $spacer * 3 * -1;
  margin-bottom: $spacer * 3 * -1;

  h3 {
    font-weight: $font-weight-bold;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 1rem;

    .actionButton {
      &::after {
        content: none !important;
      }
      // btn-sm
      @media (max-width: 768px) {
        height: initial;
        width: initial;
        padding: 0.25rem 0.5rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }
    }

    .entityViewNav {
      :global {
        a {
          color: var(--primary);
        }

        .nav-link {
          padding: 0;
        }

        .show {
          z-index: 10000;
        }
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: auto;
      row-gap: 1rem;
    }
  }
}

.leftActions {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;

  @media (max-width: 1024px) {
    grid-template-columns: auto;
    row-gap: 1rem;
  }
}

.rightActions {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  column-gap: 1rem;
  justify-self: end;

  @media (max-width: 768px) {
    justify-self: auto;
  }
}
