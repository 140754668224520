.root {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  border-radius: 6px;
  border: 1px solid var(--gray-light);
  min-height: 48px;
  padding: 0.6rem;
  width: 100%;

  &:focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  input {
    border: none;
    font-size: 14px;
    height: 24px;
    padding-bottom: 5px;
    padding: 0;

    &:focus {
      outline: transparent;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #e6e6e6;
  border-radius: 3px;
  color: var(--gray-dark);
  height: 26px;
  list-style: none;
  margin: 0 8px 8px 0;
  width: auto;

  & > span:first-child {
    max-width: 145px;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > span:last-child {
    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }

  .removeTag {
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: #333333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    padding: 0 6px;
    text-align: center;
  }
}
