.map {
  min-height: 350px;
  height: calc(100vh - 270px);
  z-index: 1;
}

.popupTitle {
  padding-bottom: 6px;
  border-bottom: 2px solid var(--primary);
}

.popupItem {
  border-bottom: 1px solid #ccc;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}
