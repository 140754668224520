@import 'assets/styles/variables.scss';

.page-item.active {
  .page-link {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.paginate {
  background-color: var(--grid-viewport-background);
}

.form-group {
  padding: 0;
  box-sizing: content-box !important;

  .form-control {
    padding: $spacer * 0.3;
    height: $spacer * 2.5;
    border-radius: 4px;

    select {
      background-color: inherit;
      border: none;
      width: 100%;
    }
  }
}
