.history-circle {
  .icon {
    color: white;
  }

  &:first-child {
    transform: none;
  }

  &-create {
    background: #4daf80;
  }

  &-update {
    background: #4daf80;
  }

  &-upload {
    background: #998bd3;
  }

  &-download {
    background: #2386a5;
  }
}
