// Don't know if .loading is being used because the class is not inside of the ./EntityMain.js
:global {
  .loading {
    min-height: 400px;
  }
}

.tab {
  margin: 1rem 2rem 0 2rem;

  @media (max-width: 768px) {
    margin: 1rem 0 0 0;

    :global {
      .nav-item {
        flex: 1 1 auto;
      }
    }
  }
}

.tabContent {
  min-height: 460px;
}
