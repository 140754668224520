@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

.font-weight-bolder {
  font-weight: $font-weight-bolder;
}

/**
 * BUTTONS
 */
.btn {
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &.btn-lg {
    border: 1px solid var(--primary);
    height: $spacer * 3;
    width: $spacer * 10;
    font-size: inherit;
  }

  &.btn-light {
    background: var(--white);
    border-color: var(--primary);
    color: var(--primary);

    &:hover {
      border-color: var(--primary);
    }
  }
}

/**
 * MODAL
 */
.modal-content {
  .modal-footer {
    border-top: none;
  }
}

.modal[aria-hidden='true'] {
  z-index: 1040;
}
