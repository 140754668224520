.root {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;

  .subtitle {
    font-weight: 700;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
}
