@import 'assets/styles/core.scss';
@import 'assets/styles/variables.scss';

$border-radius: 8px;
$border-radius-sm: 5px;
$border-none: none;
$line-height: 16px;
$dropdown-width: 16rem;

$font-weight-600: 600;
$font-weight-700: 700;
$nav-icon-dimension: 2.625rem;
$profile-icon-dimension: 2.875rem;
$app-switcher-icon-background: var(--primary);
$box-shadow-default: 4px 4px 5px rgba(0, 0, 0, 0.1);
$app-switcher-hover-background: var(--primary-lightest);

@keyframes slideLeft {
  100% {
    transform: translateX(0);
  }
}

nav {
  height: $navbar-min-height;
  font-family: $font-family-base;

  /**
  * PROFILE
  */

  .profile-icon {
    width: $profile-icon-dimension;
    height: $profile-icon-dimension;
    border-radius: $border-radius-round;
  }

  .nav-icon {
    background-color: $white;
    padding: $spacer - 0.3;
    color: var(--primary);
    border-radius: $border-radius-round;
    height: $nav-icon-dimension;
    width: $nav-icon-dimension;
    text-align: center;

    .fas,
    .far {
      transform: translateY(2px);
    }
  }

  .dropdown-group:not(:last-child) {
    border-bottom: 1px solid rgba(85, 52, 230, 0.2);
  }

  .version {
    font-size: 12px;
    padding-left: 1rem;
    display: block;
    cursor: default;
  }

  /**
  * DROPDOWN
  */
  .dropdown-container {
    a {
      text-decoration: none;
    }

    > a {
      display: block;
      padding: 0.8rem 1.5rem;
      color: var(--primary-darker);
    }

    &:hover {
      background-color: $app-switcher-hover-background;
    }

    &.lg {
      width: $spacer * 16;
    }
  }

  .dropdown-menu {
    top: $spacer * 2.6;
    border-radius: $border-radius-sm;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
    border: $border-none;
    padding: 0;
    transform: translate3d(0, 0.3rem, 0);
  }

  /**
  * APP SWITCHER
  */
  .app-switcher {
    border-radius: $border-radius-round !important;
    padding: $spacer;
    margin-right: $spacer + 0.4rem;
    margin-left: $spacer;

    i {
      display: block;
      color: $white;
      font-size: 1.4375rem;
    }
  }

  .app-switcher-icon {
    color: $white;
    background-color: $app-switcher-icon-background;
    padding: $spacer;
    border-radius: $border-radius;
    line-height: $line-height;
    font-size: $font-size-base;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: $spacer;
  }

  .app-switcher-dropdown {
    top: $spacer * 4;
    padding: $spacer/2 $spacer + 0.5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }

  .app-switcher-label {
    padding-top: $spacer;
    margin-bottom: $spacer - 0.3;
    font-weight: $font-weight-bold;
    text-indent: $spacer;
  }

  // Dropdown

  /* Used to disable built in dropdown icon */
  .dropdown-toggle,
  .dropdown {
    &::after {
      display: none !important;
    }

    a[aria-expanded='true'] {
      background-color: var(--primary-darker);
    }
  }

  .dropdown-item {
    border-radius: 6px;
    font-style: $font-style;
    font-weight: $font-weight-700;
    font-size: $spacer - 0.25rem;
    color: var(--secondary-text-color);
    line-height: $spacer - 0.063rem;
    margin: auto;
    padding-left: $spacer;
    padding-bottom: $spacer / 2;
    padding-top: $spacer / 2;

    &:hover {
      background-color: $app-switcher-hover-background;
    }

    &:last-child {
      margin-bottom: $spacer + 0.3rem;
    }
  }

  .dropdown-subheading {
    display: flex;
    padding-top: $spacer;
    color: var(--primary-darker);

    p {
      margin-bottom: 0;
    }

    i {
      color: var(--primary-darker);
      font-weight: 500;
      transform: translateY(3px);
    }
  }

  .dropdown-subheading-label {
    vertical-align: bottom;
    font-style: $font-style;
    font-weight: $font-weight-bold;
    margin-left: 7px;
    font-size: 1rem;
    line-height: 20px;
  }

  .business-name {
    font-weight: $font-weight-bold;
  }

  .nav-dropdown-toggle-icon {
    margin-left: 10px;
    font-size: 0.75rem;
  }

  .nav-dropdown {
    border-radius: 3px;
    display: block;
    margin-left: $spacer - 0.3rem;
    font-style: $font-style;
    font-weight: 500;

    &:hover {
      background-color: var(--primary);
    }
  }

  &.navbar {
    background-color: var(--primary);

    &.main-navbar {
      z-index: 1039;
      transition: background-color 0.3s ease;
    }
  }

  .notification-profile-image {
    height: $spacer * 2;
    width: $spacer * 2;
    line-height: $spacer * 2;
    text-align: center;
    border-radius: 50%;
    color: $white;
    background-color: var(--red);
  }

  /**
   * NOTIFICATION
   */
  .notification-message {
    i {
      color: var(--primary);
    }
  }
}

.navbar-items {
  .fas {
    margin-bottom: -3px;
  }

  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    max-width: 480px;
    box-shadow: 0 0 30px -4px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    position: fixed;
    width: calc(100vw - 40px);
    background: white;
    height: 100vh;
    left: 0;
    top: 0;
    transform: translateX(-120%);
    will-change: transform;
    transition: transform 0.3s ease;
    &.opened {
      transform: translateX(0);
    }
    .navbar-items-close-button {
      position: absolute;
      top: 23px;
      right: 0;
      width: 30px;
      height: 30px;
      transform: translateX(50%);
      border-radius: 50%;
      background: white;
      box-shadow: 0 0 30px -4px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }

    .navbar-nav {
      width: 100%;
      margin-bottom: 1rem;

      .nav-item {
        width: 100%;
        .nav-dropdown {
          font-weight: bold;
          padding: 1rem 1.5rem !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .dropdown-container {
        width: 100%;
      }
    }

    .nav-dropdown {
      margin: 0;
      color: black !important;

      &:hover {
        background: white;
        color: black !important;
      }
    }
    .dropdown {
      a[aria-expanded='true'] {
        background: white;
      }

      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
    .dropdown-menu {
      box-shadow: none;
      transform: translate3d(0, 0, 0);
    }

    .selected-app {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      margin-right: 0 !important;

      p {
        color: black !important;
      }
    }
  }
}

.square {
  width: 40px;
  height: 40px;
  display: block;
}
