@import 'assets/styles/variables.scss';

.circle {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &-primary {
    background: $primary;
  }

  &-success {
    background: $success;
  }

  &-gray {
    background: $gray-300;
  }
}

.text-gray {
  color: $gray-600;
}
