.root {
  width: 100%;
  height: 500px;

  // Extending the class was the best option in this case
  :global {
    .react-flow__node-default,
    .react-flow__node-input,
    .react-flow__node-output {
      width: initial;
      min-width: 80px;
    }
  }
}
