.root {
  .keyword {
    padding-left: 2.5rem !important;
  }

  .keywordIcon {
    color: var(--secondary);
    position: absolute;
    left: 28px;
    top: 16px;
    text-align: center;
    pointer-events: none;
    z-index: 2;
  }
}
