.progressBar {
  background-color: var(--primary);
  transition: width 0.2s ease;

  span {
    font-weight: 700;
    position: absolute;
    left: 50%;
  }
}

.listItem {
  display: flex;
  align-items: center;
  border: none;
  padding: 0;

  &:nth-of-type(even) {
    background-color: var(--grid-viewport-background);
  }

  label {
    margin-bottom: 0;
  }

  input {
    margin-left: 0.65rem;
  }

  input,
  label {
    cursor: pointer;
  }

  .lineThrough {
    text-decoration: line-through;
  }

  .detail {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0 0.2rem 1.95rem;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }
}
