.EntityLogListItem {
  display: flex;
  align-items: center;
  margin-top:-8px;

  .log-action {
    .circle {
      &:last-child {
        transform: translateX(-20%);
      }
    }
  }

  .log-detail-date {
    font-size: 0.9rem;
  }
}
