.SchemaCard {
  .entity-card {
    cursor: pointer;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    margin-bottom: 14px;
    padding: 24px;
    height: 120px;
    overflow: hidden;

    &.is-workflow {
      background: #f8ffe9;
    }

    &:hover {
      background-color: var(--primary);
      color: #fff;

      h5,
      .text-muted {
        color: #fff !important;
      }
    }

    .workflow,
    .entity-key {
      font-size: 12px;
    }
  }
}
