.laneStyle {

  :global {
    .horizontal {
      &>section {
        &>div {
          max-height: calc(100vh - 330px)
        }
      }
    }
  }
}
